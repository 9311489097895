import Head from "next/head";

type TProps = {
  currentURL: string;
  description?: string;
  title: string;
  siteTitle?: string;
  previewImage?: string;
  microdata?: Record<string, string | number> | null;
};

export default function SEO({
  currentURL,
  description = "Movi es el sitio web más fácil y seguro para comprar y vender autos usados en Costa Rica🇨. Publicá tu auto de forma gratuita y empezá a recibir ofertas.",
  title = "Los mejores autos usados en Costa Rica",
  siteTitle = "Movi Autos",
  previewImage = "https://res.cloudinary.com/movi-prod/image/upload/v1678198413/SEO/ogImage_hoaepd.png",
  microdata,
}: TProps) {
  return (
    <Head>
      <title>{`${title} | ${siteTitle}`}</title>
      <meta name="description" content={description} />
      {/* <meta property="twitter:creator" content="@SundialCalendar" /> */}

      {/* Twitter */}

      <meta name="twitter:card" content="summary_large_image" key="twcard" />
      <meta name="twitter:creator" content="Movi Autos" />
      <meta name="twitter:title" content={title} key="twtitle" />
      <meta
        name="twitter:description"
        content={description}
        key="twdescription"
      />
      <meta name="twitter:image" content={previewImage} key="twimage" />
      {/* <meta name="twitter:creator" content="SundialCalendar" key="twhandle" /> */}

      {/* Open Graph */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={currentURL} key="ogurl" />
      <meta property="og:image" content={previewImage} key="ogimage" />
      <meta property="og:site_name" content={siteTitle} key="ogsitename" />
      <meta property="og:title" content={title} key="ogtitle" />
      <meta property="og:description" content={description} key="ogdesc" />

      {/* FB */}
      <meta
        property="fb:app_id"
        content={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}
      />

      {microdata && Object.keys(microdata)?.length
        ? Object.keys(microdata).map((k) => (
            <meta
              key={k}
              property={k}
              content={microdata[k].toLocaleString()}
            />
          ))
        : null}
    </Head>
  );
}
